/*
 * faff.js is the entry point into the website code. It is responsible for application setup and loading all other javascript (modules) on the page by looking for selectors in the
 * HTML and importing the JS if it finds a match. In order to import scripts dynamically we are relying on Webpack https://webpack.js.org/guides/code-splitting/#dynamic-imports.
 * Webpack will end up creating a script file for each module on the page.
*/

/*
 * GLOBAL
 *
 * Global imports and application setup.
 */
// eslint-disable-next-line no-unused-vars
import 'lazysizes'
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks'
import { notice } from './js/notice'
import { appEventEmitter } from './js/app-event-emitter'
import { quickLinks } from './js/quick-links'

notice(document, appEventEmitter)
quickLinks(document, appEventEmitter)
loadModules()

appEventEmitter.getAppInfo() // Called after all other code so that code has a chance to attach event listeners before any dispatching happens.
appEventEmitter.addEventListener('run-faff', () => loadModules())

/*
 * MODULES (selector dependent code)
 *
 * A couple of guidelines for harmonious coding.
 * 1. Every module path is relative to "src/js/modules" since it is too confusing to group our javascript in the same directories as our React view layer and JS might span across multiple
 * React components / modules.
 * 2. Every module should default export a function that takes the "window.document", the selector HTML, and the app event emitter as the first 3 arguments. If the module needs
 * other data passed to it then the recommended approach is to use a path to a factory function instead of the path to the module.
 * 3. Modules should be self contained so generally accessing the DOM outside of the module selector is frowned upon.
 * 4. Modules selectors should be data attributes that starts with "data-js". This makes it clear what the javascript is using to hook into the DOM with. Other selectors like
 * classes, IDs, or elements already serve other purposes such as classes controlling styling and IDs being used for jump links. When we remove a style or jump link we do not
 * want to worry about our javascript breaking.
 */
function loadModules () {
  const modules = [
    {
      path: 'uuid-form-input',
      selector: 'input[name="uuid"]'
    },
    {
      path: 'call-cta',
      selector: '[data-js-call-center-open]',
      postInitAction () {
        return appEventEmitter.getAppInfo()
      }
    },
    {
      path: 'ppc-phone-swap',
      selector: '[href^="tel:"]'
    },
    {
      path: 'navigation',
      selector: '[data-js-mobile-nav]'
    },
    {
      path: 'video',
      selector: '[data-js-video]'
    },
    {
      path: 'local-product-card',
      selector: '[data-js-local-product-card]'
    },
    {
      path: 'calculator',
      selector: '[data-js-calculator]'
    },
    {
      path: 'tab-content',
      selector: '[data-js-tab-content]'
    },
    {
      path: 'search-factory',
      selector: '[data-js-search]'
    },
    {
      path: 'lightbox-modal',
      selector: '[data-js-lightbox-modal]'
    },
    {
      path: 'carousel-factory',
      selector: '[data-js-carousel]'
    },
    {
      path: 'phone-input-masking',
      selector: '[data-js-phone-input-masking]'
    },
    {
      path: 'address-autocomplete',
      selector: '[data-js-address-autocomplete]'
    },
    {
      path: 'active-nav-item',
      selector: '[data-js-active-nav-item]'
    },
    {
      path: 'date-picker',
      selector: '[data-js-date-picker]',
      postInitAction () {
        const daysIntoTheFuture = 60
        return appEventEmitter.getHolidays(new Date(), daysIntoTheFuture)
      }
    },
    {
      path: 'field-dependency',
      selector: '[data-js-dependent-field]'
    },
    {
      path: 'required-checkboxes',
      selector: 'input[type="checkbox"]:required'
    },
    {
      path: 'form-stepper',
      selector: '[data-js-form-stepper]'
    },
    {
      path: 'iframe-module',
      selector: '[data-js-iframe-module]'
    },
    {
      path: 'zip-search',
      selector: '[data-js-zip-search]'
    },
    {
      path: 'accordion-content',
      selector: '[data-js-accordion-content]'
    },
    {
      path: 'fieldset-suboptions',
      selector: '[data-js-fieldset-suboptions]'
    }
  ]

  modules.forEach((module) => {
    const modulesHTML = document.querySelectorAll(module.selector)

    if (modulesHTML.length === 0) return

    import(
      // Magic Webpack comments documented here https://webpack.js.org/api/module-methods/#magic-comments.
      /* webpackChunkName: "[request]-[index]" */
      './js/chunks/' + module.path
    )
      .then(({ default: moduleFn }) => modulesHTML.forEach((moduleHTML) => moduleFn(document, moduleHTML, appEventEmitter)))
      .then(() => module.postInitAction && module.postInitAction())
  })
}
